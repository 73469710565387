<template>
  <div class="mb-5">
    <b-row class="my-2">
      <b-col md="12">
        <h1 class="text-center">
          Veuillez vous assurer que les informations entrées sont correctes puis
          enregistrez le formulaire
        </h1>
      </b-col></b-row
    >
    <b-card class="preview-document">
      <!-- entete -->

      <b-row>
        <b-col cols="12">
          <!-- content -->
          <div v-if="infos" class="pl-1 pr-1">
            <!-- infos de base -->
            <div class="customborder1 p-2">
              <b-row>
                <b-col cols="12" class="text-right">
                  <small class=""
                    >(Cliquer sur ce bouton pour modifier la section)</small
                  >
                  <b-img
                    title="Modifier cette section"
                    class="cursor-pointer"
                    width="20"
                    height="20"
                    :src="editIconGreen"
                    @click="editSection('header')"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6" class="mb-1">
                  Année : <strong> {{ infos ? infos.annee : "" }}</strong>
                </b-col>

                <b-col md="6" class="mb-1">
                  Mois : <strong> {{ infos ? infos.mois : "" }}</strong>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6" class="mb-1">
                  Département: <strong> {{ infos.departement }}</strong>
                </b-col>
                <b-col md="6" class="mb-1">
                  Commune: <strong> {{ infos.commune }}</strong>
                </b-col>
                <b-col md="6">
                  Arrondissement: <strong> {{ infos.arrondissement }}</strong>
                </b-col>
                <b-col md="6">
                  Quartier/Village: <strong> {{ infos.quartier }}</strong>
                </b-col>
              </b-row>
            </div>

            <!-- renseignement generaux -->
            <div class="bg-primary">
              <b-row class="text-center mt-1 p-1">
                <b-col
                  md="10"
                  class="text-left text-white d-flex align-items-center"
                >
                  <strong> I- RENSEIGNEMENTS GÉNÉRAUX </strong>
                </b-col>
                <b-col md="2" class="text-right text-white pr-2">
                  <b-img
                    title="Modifier cette section"
                    class="cursor-pointer"
                    width="20"
                    height="20"
                    :src="editIcon"
                    @click="editSection('renseignements-generaux')"
                  />
                </b-col>
              </b-row>
            </div>

            <div class="customborder1 p-2">
              <b-row>
                <b-col md="6" class="mb-1">
                  Nom de l'établissement:
                  <strong> {{ infos.nomEtablissement }}</strong>
                </b-col>
                <b-col md="3" class="mb-1">
                  Nature du site: <strong>{{ get_sous_type }}</strong>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="4" class="mb-1">
                  Nom du promoteur: <strong>{{ infos.nomPromoteur }}</strong>
                </b-col>
                <b-col md="4" class="mb-1">
                  Prénoms du promoteur:
                  <strong>{{ infos.prenomsPromoteur }}</strong>
                </b-col>
                <b-col md="4">
                  Nationalité du promoteur:
                  <strong>{{ infos.nationalitePromoteur }}</strong>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="12" class="mb-1">
                  Adresse complète de l'établissement:
                  <strong>{{ infos.adresseComplete }}</strong>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12" class="mb-1">
                  Type de transport:
                  <strong>{{ infos.typeTransport }}</strong>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="4" class="mb-1">
                  Détenez-vous un agrément du Ministère en charge ?
                </b-col>
                <b-col v-if="infos.referenceAgrement" md="4" class="ml-2">
                  <strong> oui, {{ infos.referenceAgrement }}</strong>
                </b-col>
                <b-col v-else md="4">
                  <strong> non </strong>
                </b-col>
              </b-row>
              <!-- telephones -->
              <b-row>
                <b-col md="4" class="mb-1">
                  Téléphone 1 :
                  <strong>{{ infos.telephone1 }}</strong>
                </b-col>
                <b-col md="4" class="mb-1" v-if="infos.telephone2">
                  Téléphone 2 :
                  <strong>{{ infos.telephone2 }}</strong>
                </b-col>
                <b-col md="4" class="mb-1" v-if="infos.telephone3">
                  Téléphone 3 :
                  <strong>{{ infos.telephone3 }}</strong>
                </b-col>
              </b-row>
            </div>

            <!-- Capcaité d'acceuil -->
            <div class="bg-primary">
              <b-row class="text-center mt-1 p-1">
                <b-col
                  md="10"
                  class="text-left text-white d-flex align-items-center"
                >
                  <strong> II- CAPACITÉ D'ACCEUIL </strong>
                </b-col>
                <b-col md="2" class="text-right text-white pr-2">
                  <b-img
                    title="Modifier cette section"
                    class="cursor-pointer"
                    width="20"
                    height="20"
                    :src="editIcon"
                    @click="editSection('capacite-acceuil')"
                  />
                </b-col>
              </b-row>
            </div>

            <div class="customborder1 p-2">
              <b-row>
                <b-col md="12" class="mb-1">
                  Nombre de places disponibles :
                  <strong>{{
                    infos.capacitesAccueil.nombrePlacesDisponibles
                  }}</strong>
                </b-col>
                <b-col md="12" class="mb-1">
                  Nombre de visites nationaux :
                  <strong>
                    {{ infos.capacitesAccueil.nombreVisitesNationaux }}</strong
                  >
                </b-col>
                <b-col md="12" class="mb-1">
                  Nombre de visites internationaux :
                  <strong>
                    {{
                      infos.capacitesAccueil.nombreVisitesInterNationaux
                    }}</strong
                  >
                </b-col>
              </b-row>
            </div>

            <div class="bg-primary">
              <b-row class="text-center mt-1 p-1">
                <b-col
                  md="10"
                  class="text-left text-white d-flex align-items-center"
                >
                  <strong> III- EMPLOIS </strong>
                </b-col>
              </b-row>
            </div>

            <div class="customborder1 p-2">
              <!-- employés salariés -->
              <div class="table-responsive text-center">
                <div class="d-flex">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col" rowspan="2">
                          Nombre d'employés salariés
                        </th>
                        <th scope="col" colspan="2">Hommes</th>
                        <th scope="col" colspan="2">Femmes</th>
                        <th scope="col" colspan="2">Total</th>
                      </tr>
                      <tr>
                        <th scope="col">Temps plein</th>
                        <th scope="col">Temps partiel</th>
                        <th scope="col">Temps plein</th>
                        <th scope="col">Temps partiel</th>
                        <th scope="col">Temps plein</th>
                        <th scope="col">Temps partiel</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">Nationaux</th>
                        <td>
                          {{ infos.employesSalaries.hommeTempspleinNationaux }}
                        </td>
                        <td>
                          {{
                            infos.employesSalaries.hommeTempspartielNationaux
                          }}
                        </td>
                        <td>
                          {{ infos.employesSalaries.femmeTempspleinNationaux }}
                        </td>
                        <td>
                          {{
                            infos.employesSalaries.femmeTempspartielNationaux
                          }}
                        </td>
                        <td>
                          {{
                            infos.employesSalaries.hommeTempspleinNationaux +
                            infos.employesSalaries.femmeTempspleinNationaux
                          }}
                        </td>
                        <td>
                          {{
                            infos.employesSalaries.hommeTempspartielNationaux +
                            infos.employesSalaries.femmeTempspartielNationaux
                          }}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Étrangers</th>
                        <td>
                          {{ infos.employesSalaries.hommeTempspleinEtrangers }}
                        </td>
                        <td>
                          {{
                            infos.employesSalaries.hommeTempspartielEtrangers
                          }}
                        </td>
                        <td>
                          {{ infos.employesSalaries.femmeTempspleinEtrangers }}
                        </td>
                        <td>
                          {{
                            infos.employesSalaries.femmeTempspartielEtrangers
                          }}
                        </td>
                        <td>
                          {{
                            infos.employesSalaries.hommeTempspleinEtrangers +
                            infos.employesSalaries.femmeTempspleinEtrangers
                          }}
                        </td>
                        <td>
                          {{
                            infos.employesSalaries.hommeTempspartielEtrangers +
                            infos.employesSalaries.femmeTempspartielEtrangers
                          }}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Total</th>
                        <td>
                          {{
                            infos.employesSalaries.hommeTempspleinNationaux +
                            infos.employesSalaries.hommeTempspleinEtrangers
                          }}
                        </td>
                        <td>
                          {{
                            infos.employesSalaries.hommeTempspartielNationaux +
                            infos.employesSalaries.hommeTempspartielEtrangers
                          }}
                        </td>
                        <td>
                          {{
                            infos.employesSalaries.femmeTempspleinNationaux +
                            infos.employesSalaries.femmeTempspleinEtrangers
                          }}
                        </td>
                        <td>
                          {{
                            infos.employesSalaries.femmeTempspartielNationaux +
                            infos.employesSalaries.femmeTempspartielEtrangers
                          }}
                        </td>
                        <td>
                          {{
                            infos.employesSalaries.hommeTempspleinNationaux +
                            infos.employesSalaries.femmeTempspleinNationaux +
                            infos.employesSalaries.hommeTempspleinEtrangers +
                            infos.employesSalaries.femmeTempspleinEtrangers
                          }}
                        </td>
                        <td>
                          {{
                            infos.employesSalaries.hommeTempspartielNationaux +
                            infos.employesSalaries.femmeTempspartielNationaux +
                            infos.employesSalaries.hommeTempspartielEtrangers +
                            infos.employesSalaries.femmeTempspartielEtrangers
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <b-img
                    title="Modifier cette section"
                    class="cursor-pointer"
                    width="20"
                    height="20"
                    :src="editIconGreen"
                    @click="editSection('emplois-salaries')"
                  />
                </div>
              </div>
              <!-- nouveeaux employés du mois  -->
              <div class="table-responsive text-center mt-3">
                <div class="d-flex">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">Salarié recrutés au cours du mois</th>
                        <th scope="col">Hommes</th>
                        <th scope="col">Femmes</th>
                        <th scope="col">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">Nombre</th>
                        <td>{{ infos.nouveauEmplois.hommes }}</td>
                        <td>{{ infos.nouveauEmplois.femmes }}</td>
                        <td>
                          {{
                            infos.nouveauEmplois.hommes +
                            infos.nouveauEmplois.femmes
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <b-img
                    title="Modifier cette section"
                    class="cursor-pointer"
                    width="20"
                    height="20"
                    :src="editIconGreen"
                    @click="editSection('emplois-nouveaux')"
                  />
                </div>
              </div>
              <!-- employés non salariés -->
              <div class="table-responsive text-center mt-3">
                <div class="d-flex">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col" rowspan="2">
                          Nombre d'employés non salariés
                        </th>
                        <th scope="col" colspan="2">Hommes</th>
                        <th scope="col" colspan="2">Femmes</th>
                        <th scope="col" colspan="2">Total</th>
                      </tr>
                      <tr>
                        <th scope="col">Temps plein</th>
                        <th scope="col">Temps partiel</th>
                        <th scope="col">Temps plein</th>
                        <th scope="col">Temps partiel</th>
                        <th scope="col">Temps plein</th>
                        <th scope="col">Temps partiel</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">Nationaux</th>
                        <td>
                          {{
                            infos.employesNonSalaries.hommeTempspleinNationaux
                          }}
                        </td>
                        <td>
                          {{
                            infos.employesNonSalaries.hommeTempspartielNationaux
                          }}
                        </td>
                        <td>
                          {{
                            infos.employesNonSalaries.femmeTempspleinNationaux
                          }}
                        </td>
                        <td>
                          {{
                            infos.employesNonSalaries.femmeTempspartielNationaux
                          }}
                        </td>
                        <td>
                          {{
                            infos.employesNonSalaries.hommeTempspleinNationaux +
                            infos.employesNonSalaries.femmeTempspleinNationaux
                          }}
                        </td>
                        <td>
                          {{
                            infos.employesNonSalaries
                              .hommeTempspartielNationaux +
                            infos.employesNonSalaries.femmeTempspartielNationaux
                          }}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Étrangers</th>
                        <td>
                          {{
                            infos.employesNonSalaries.hommeTempspleinEtrangers
                          }}
                        </td>
                        <td>
                          {{
                            infos.employesNonSalaries.hommeTempspartielEtrangers
                          }}
                        </td>
                        <td>
                          {{
                            infos.employesNonSalaries.femmeTempspleinEtrangers
                          }}
                        </td>
                        <td>
                          {{
                            infos.employesNonSalaries.femmeTempspartielEtrangers
                          }}
                        </td>
                        <td>
                          {{
                            infos.employesNonSalaries.hommeTempspleinEtrangers +
                            infos.employesNonSalaries.femmeTempspleinEtrangers
                          }}
                        </td>
                        <td>
                          {{
                            infos.employesNonSalaries
                              .hommeTempspartielEtrangers +
                            infos.employesNonSalaries.femmeTempspartielEtrangers
                          }}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Total</th>
                        <td>
                          {{
                            infos.employesNonSalaries.hommeTempspleinNationaux +
                            infos.employesNonSalaries.hommeTempspleinEtrangers
                          }}
                        </td>
                        <td>
                          {{
                            infos.employesNonSalaries
                              .hommeTempspartielNationaux +
                            infos.employesNonSalaries.hommeTempspartielEtrangers
                          }}
                        </td>
                        <td>
                          {{
                            infos.employesNonSalaries.femmeTempspleinNationaux +
                            infos.employesNonSalaries.femmeTempspleinEtrangers
                          }}
                        </td>
                        <td>
                          {{
                            infos.employesNonSalaries
                              .femmeTempspartielNationaux +
                            infos.employesNonSalaries.femmeTempspartielEtrangers
                          }}
                        </td>
                        <td>
                          {{
                            infos.employesNonSalaries.hommeTempspleinNationaux +
                            infos.employesNonSalaries.femmeTempspleinNationaux +
                            infos.employesNonSalaries.hommeTempspleinEtrangers +
                            infos.employesNonSalaries.femmeTempspleinEtrangers
                          }}
                        </td>
                        <td>
                          {{
                            infos.employesNonSalaries
                              .hommeTempspartielNationaux +
                            infos.employesNonSalaries
                              .femmeTempspartielNationaux +
                            infos.employesNonSalaries
                              .hommeTempspartielEtrangers +
                            infos.employesNonSalaries.femmeTempspartielEtrangers
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <b-img
                    title="Modifier cette section"
                    class="cursor-pointer"
                    width="20"
                    height="20"
                    :src="editIconGreen"
                    @click="editSection('emplois-non-salaries')"
                  />
                </div>
              </div>
            </div>
            <!-- renseignment exploitation du mois -->
            <div class="bg-primary">
              <b-row class="text-center mt-1 p-1">
                <b-col
                  md="10"
                  class="text-left text-white d-flex align-items-center"
                >
                  <strong>
                    IV- RENSEIGENEMENT SUR L'EXPLOITATION DU MOIS
                  </strong>
                </b-col>
                <b-col md="2" class="text-right text-white pr-2">
                  <b-img
                    title="Modifier cette section"
                    class="cursor-pointer"
                    width="20"
                    height="20"
                    :src="editIcon"
                    @click="editSection('exploitation-mois')"
                  />
                </b-col>
              </b-row>
            </div>

            <div class="customborder1 p-2">
              <b-row>
                <b-col md="12" class="mb-1">
                  Durrée moyenne des visites :
                  <strong> {{ infos.durreeMoyenneVisite }}</strong>
                </b-col>
                <b-col md="12" class="mb-1">
                  Chiffre d'affaire global nationaux (FCFA) :
                  <strong> {{ infos.chiffreAffaireGlobalNationaux }}</strong>
                </b-col>
                <b-col md="12" class="mb-1">
                  Chiffre d'affaire global internationaux (FCFA) :
                  <strong>{{
                    infos.chiffreAffaireGlobalInterNationaux
                  }}</strong>
                </b-col>
                <b-col md="12" class="mb-1">
                  Chiffre d'affaire global total (FCFA) :
                  <strong>
                    {{
                      normalizeNumericValue(
                        infos.chiffreAffaireGlobalInterNationaux
                      ) +
                      normalizeNumericValue(infos.chiffreAffaireGlobalNationaux)
                    }}
                  </strong>
                </b-col>
                <b-col md="12" class="mb-1">
                  "Charge salariales (FCFA)* :
                  <strong>{{ infos.chargesSalariales }}</strong>
                </b-col>
                <b-col md="12" class="mb-1">
                  "Autres Dépenses (FCFA)* :
                  <strong>{{ infos.autresDepense }}</strong>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="px-1 mt-2">
        <b-col md="6">
          <div class="text-left">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="secondary"
              class="col-xl-4"
              @click="edit()"
            >
              Retourner sur le formulaire
            </b-button>
          </div>
        </b-col>
        <b-col md="6">
          <div class="text-right">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="col-xl-4"
              @click="save()"
            >
              <span v-if="isSavingForm" class="d-flex align-items-center">
                <div class="mr-2"><b-spinner /></div>

                <span class=""> Validation du formulaire... </span>
              </span>
              <span v-if="!isSavingForm"> Enregistrer les informations </span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card>

    <!-- modal backdrop -->
    <b-modal
      :ref="sectionEditionModalRef"
      scrollable
      hide-footer
      no-close-on-backdrop
      content-class="shadow"
      title="Modifier cette section"
      size="xl"
    >
      <component
        :is="sectionToEditComponent"
        :input-data="sectionToEditData"
        :show-edition-buttons="true"
        @onFormSuccess="onEditionFormSuccess($event)"
        @onCancel="hideSectionEditionModal"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  BCol,
  BModal,
  BRow,
  BButton,
  BCard,
  BCardHeader,
  BCardBody,
  BFormRadioGroup,
  BListGroup,
  BListGroupItem,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormRadio,
  BForm,
  BCardText,
  VBTooltip,
  BImg,
  BSpinner,
} from "bootstrap-vue";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import axios from "axios";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapActions } from "vuex";
import AppLogo from "@/components/AppLogo.vue";
import localstorageService from "@/services/localstorage/localstorage.service";
import utilsService from "@/services/utils/utils.service";
import formulaireStoreModule from "@/store/formulaire";
import {
  registerStoreModule,
  unregisterStoreModule,
} from "@/helpers/vuex-utils";
import FormHeader from "./sections/FormHeader.vue";
import FormRenseignementsGeneraux from "./sections/FormRenseignementsGeneraux.vue";
import FormCapaciteAcceuil from "./sections/FormCapaciteAcceuil.vue";
import FormEmplois from "./sections/emplois/FormEmplois.vue";
import FormSalaries from "./sections/emplois/FormSalaries.vue";
import FormNouveauxEmployesDuMois from "./sections/emplois/FormNouveauxEmployesDuMois.vue";
import FormNonSalaries from "./sections/emplois/FormNonSalaries.vue";
import FormExploitationMois from "./sections/FormExploitationMois.vue";

export default {
  setup() {
    const requiredStoreModules = [
      { path: "formulaire", module: formulaireStoreModule },
    ];
    // Register module
    registerStoreModule(requiredStoreModules);

    return { requiredStoreModules };
  },
  components: {
    BModal,
    BCol,
    BRow,
    BButton,
    BCard,
    BCardHeader,
    BCardBody,
    BFormRadioGroup,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormRadio,
    BForm,
    BCardText,
    BSpinner,
    vSelect,
    ToastificationContent,
    AppLogo,
    VBTooltip,
    BImg,

    // entete
    FormHeader,

    // renseignements generaux
    FormRenseignementsGeneraux,

    // capacité d'acceuil
    FormCapaciteAcceuil,

    // emplois
    FormEmplois,
    FormSalaries,
    FormNouveauxEmployesDuMois,
    FormNonSalaries,

    // exploitation
    FormExploitationMois,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      isSavingForm: false,
      infos: null,
      preview: null,
      isProcessingProvidedInput: true,
      sectionEditionModalRef: "edit-section-modal",
      sectionToEditComponent: null,
      sectionToEditData: null,
      editIconGreen: require("@/assets/images/icons/edit vert.png"),
      editIcon: require("@/assets/images/icons/edit.png"),
      currentUserId: localstorageService.getUserId(),
    };
  },
  computed: {
    layoutContentRenderer() {
      const rendererType = this.$route.meta.contentRenderer;
      if (rendererType === "sidebar-left") {
        return "layout-content-renderer-left";
      }
      if (rendererType === "sidebar-left-detached") {
        return "layout-content-renderer-left-detached";
      }
      return "layout-content-renderer-default";
    },
    currentUserSousTypeEtabissement() {
      const { currentUserId, etablissementDetails } =
        utilsService.currentUserUtils();
      const { sousTypeEtablissement } = etablissementDetails;
      console.log(etablissementDetails);
      return sousTypeEtablissement;
    },
    get_sous_type() {
      const { currentUserId, etablissementDetails } =
        utilsService.currentUserUtils();
      const { sousTypeEtablissement } = etablissementDetails;
      return sousTypeEtablissement;
    },
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules);
  },
  mounted() {
    if (this.$route.params.data && this.$route.params.preview) {
      const { data, isEditionOperation } = this.$route.params;

      this.infos = {
        ...data,
      };
      if (isEditionOperation) this.infos.editor = this.currentUserId;

      this.preview = this.$route.params.preview;
    } else {
      this.$router.push({
        name: "formulaires-create",
        params: { slug: this.$route.params.slug },
      });
    }
  },
  methods: {
    ...mapActions("formulaire", {
      addFormulaire: "addFormulaire",
      updateFormulaire: "updateFormulaire",
    }),
    onEditionFormSuccess(evt) {
      this.infos = { ...this.infos, ...evt };
      this.hideSectionEditionModal();
    },
    // eslint-disable-next-line consistent-return
    getSectionToEditComponent(section) {
      switch (section) {
        case "header":
          return "FormHeader";
        case "renseignements-generaux":
          return "FormRenseignementsGeneraux";
        case "capacite-acceuil":
          return "FormCapaciteAcceuil";
        case "emplois-salaries":
          return "FormSalaries";
        case "emplois-nouveaux":
          return "FormNouveauxEmployesDuMois";
        case "emplois-non-salaries":
          return "FormNonSalaries";
        case "exploitation-mois":
          return "FormExploitationMois";
        default:
          break;
      }
    },
    save() {
      const { currentUserId, etablissementDetails, userRoleId } =
        utilsService.currentUserUtils();
      this.isSavingForm = true;
      const { isEditionOperation } = this.$route.params;
      const successMessage = !isEditionOperation
        ? "Formulaire enregistré avec succès"
        : "Opération réussie";
      const serviceMethode = isEditionOperation
        ? "updateFormulaire"
        : "addFormulaire";
      const { _id } = this.infos;
      delete this.infos._id;
      let userData = localstorageService.getUserData();
      const data = {
        formType: "forms-stats-cultures",
        ...(isEditionOperation && { id: _id }),
        data: {
          ...this.infos,
          statut: userData.canValidateFicheStatMensuel || userData.role.code === "promo_etablissement" ? "valide" : "attente",
        },
      };
      if (isEditionOperation) data.data.editor = this.currentUserId;

      
      if (currentUserId) {
        data.data.auteur = currentUserId;
        data.data.auteurRole = userData.role._id;
      }

      // calcul total CA global
      data.data.chiffreAffaireGlobalTotal =
        data.data.chiffreAffaireGlobalNationaux +
        data.data.chiffreAffaireGlobalInterNationaux;

      if (etablissementDetails._id)
        data.data.etablissement = etablissementDetails._id;
      if (etablissementDetails.secteur)
        data.data.secteur = etablissementDetails.secteur;

      this[serviceMethode](data)
        .then((response) => {
          if (response.status === 200) {
            if (
              this.$store.state.auth &&
              this.$store.state.auth.isAuthenticated
            ) {
              this.$router.push({
                name: "dashboard",
              });

              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: successMessage,
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
            } else {
              this.$router.push({
                name: "home-public",
              });
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: successMessage,
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Une erreur est suvenue, veuillez rééssayer",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
          this.isSavingForm = false;
        })
        .catch((error) => {
          this.isSavingForm = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title:
                error.message || "Une erreur est suvenue, veuillez rééssayer",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
    showSectionEditionModal() {
      this.$refs[this.sectionEditionModalRef].show();
    },
    hideSectionEditionModal() {
      this.$refs[this.sectionEditionModalRef].hide();
    },
    editSection(section) {
      this.sectionToEditComponent = this.getSectionToEditComponent(section);
      this.sectionToEditData = JSON.parse(JSON.stringify(this.infos));

      this.showSectionEditionModal();
    },
    edit() {
      this.$router.push({
        name: "formulaires-create",
        params: { slug: this.$route.params.slug, data: this.infos },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/base/themes/bordered-layout.scss";

.customborder1 {
  border: 1px solid rgba(0, 0, 0, 0.3);
  // padding: 15px 35px;
}

.customborder2 {
  border: 1px solid rgba(0, 0, 0, 0.3);
  // padding: 0 35px 15px;
}

table {
  td,
  th,
  tr {
    text-align: left;
  }
}

thead {
  th {
    background-color: #0e6258 !important;
    color: white;
  }
}
</style>
